import { Author } from 'component-library'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useRef } from 'react'
import {
  useQueryParamString,
  clearQueryParam,
} from 'react-use-query-param-string'
import DemoHero from '../DemoHero/DemoHero'
import DemoShareLinks from '../DemoShareLinks/DemoShareLinks'
import copy from 'copy-to-clipboard'
import { Script } from 'gatsby'
import { IContentAuthor } from '../../types'
import { sendCustomGAEvent } from 'shared-utilities'
import {
  GetElementsByAttribute,
  getImageByTypeFromMetaData,
} from '../../utils/demo_helpers'
import { ToastContainer, toast } from 'react-toastify'

export interface IDemoMainProps {
  title: string
  previewImages: Queries.Frontmatter['previewImages']
  date_of_publication: string
  date_of_last_modification: string
  html: string
  inlineScriptSources: string[]
  currentExecutingScriptIndex: number
  setCurrentExecutingScriptIndex: (number: number) => void
  contentAuthors: IContentAuthor[]
  totalTime: string[]
  discussionForumUrl: string
}

const DemoMain = ({
  title,
  previewImages,
  date_of_publication,
  date_of_last_modification,
  html,
  inlineScriptSources,
  currentExecutingScriptIndex,
  setCurrentExecutingScriptIndex,
  contentAuthors,
  totalTime,
  discussionForumUrl,
}: IDemoMainProps) => {
  const { user, isLoading } = useAuth0()
  useEffect(() => {
    if (document && !isLoading) {
      document.querySelectorAll('pre').forEach((codeBlock, index) => {
        const codeString = codeBlock.innerText
        const button = document.createElement('button')
        button.className = 'copy-code-button'
        button.type = 'button'
        button.ariaLabel = 'copy to clipboard'
        button.setAttribute('data-clipboard-text', codeString)
        button.innerHTML = `<i class="bx bx-copy"></i>`
        button.onclick = () => {
          copy(codeString)
          toast.success('Copied to clipboard')
          sendCustomGAEvent('copy_codeblock', {
            event_category: 'demo',
            event_label: title,
            value: JSON.stringify({
              copied_block_id: `${index + 1}`,
              profile_id: user?.sub || '',
            }),
          })
        }
        codeBlock.appendChild(button)
      })
    }
  }, [isLoading])

  const getMathSpans =
    GetElementsByAttribute(
      'span',
      'class',
      'math notranslate nohighlight',
      html
    ) || []
  const getMathDivs =
    GetElementsByAttribute(
      'div',
      'class',
      'math notranslate nohighlight',
      html
    ) || []
  const getMathElements = () => {
    return Array.from(getMathSpans).concat(Array.from(getMathDivs))
  }

  const formatEquations = () => {
    let newHTML = `${html}`
    const mathElements = getMathElements()
    if (mathElements.length) {
      mathElements.forEach((element) => {
        newHTML = newHTML.replace(
          element,
          element
            .replaceAll(`\\(`, '')
            .replaceAll(`\\)`, '')
            .replaceAll(`\\[`, '')
            .replaceAll(`\\]`, '')
            .replace(
              '<span class="math notranslate nohighlight">',
              '<span class="math notranslate nohighlight">$'
            )
            .replace('</span>', '$</span>')
            .replace(
              '<div class="math notranslate nohighlight">',
              () => '<div class="math notranslate nohighlight">\n\n$$$$'
            )
            .replace('</div>', () => `\n\n$$$$\n\n</div>`)
        )
      })
    }
    return newHTML
  }

  const getFinalHTML = () => {
    const html = formatEquations()
    /** RegEx to remove title, author and publish dates from the final html **/
    const regex =
      /(<h1[^>]*?>.*?<\/h1>)|(<p><em>Author.*?Posted:.*?(?:Last updated:.*?)?<\/em><\/p>)/gs
    const newHTML = html
      .replace(regex, '')
      .replaceAll('<img', '<img loading="lazy" decoding="async" ')
    return newHTML
  }

  // Increment currentExecutingScriptIndex to run inline scripts one after the other.
  const executeNextScriptCallback = () => {
    if (inlineScriptSources[currentExecutingScriptIndex + 1]) {
      setCurrentExecutingScriptIndex(currentExecutingScriptIndex + 1)
    }
  }

  const commentBox = useRef<HTMLDivElement | null>(null)

  const [utterancesSession] = useQueryParamString('utterances', '')

  useEffect(() => {
    // https://github.com/utterance/utterances/issues/580
    // To prevent user session token from getting added to open issues
    // We have to manually delete the `utterances` searchParam after storing it in localStorage
    if (utterancesSession)
      localStorage.setItem('utterances-session', utterancesSession)
    clearQueryParam('utterances')
    const scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', 'https://utteranc.es/client.js')
    scriptEl.setAttribute('crossorigin', 'anonymous')
    scriptEl.setAttribute('async', 'true')
    scriptEl.setAttribute('repo', 'PennyLaneAI/comments')
    scriptEl.setAttribute('issue-term', 'pathname')
    scriptEl.setAttribute('theme', 'github-light')
    commentBox.current?.appendChild(scriptEl)
  }, [])
  return (
    <section className="demo-section" data-testid="demo-section">
      <DemoHero
        title={title}
        contentAuthors={contentAuthors}
        image={getImageByTypeFromMetaData(previewImages, 'hero_image')}
        publicationDate={date_of_publication}
        modificationDate={date_of_last_modification}
      />
      <div
        className="demos-compiled-container"
        dangerouslySetInnerHTML={{ __html: getFinalHTML() }}
      />
      {/* Execute scripts sequentially as they might be dependent on each other */}
      {inlineScriptSources.map((script, index) =>
        script && currentExecutingScriptIndex >= index ? (
          <Script
            key={index}
            id={`script-inline-${index + 1}`}
            src={script}
            strategy="idle"
            onLoad={() => executeNextScriptCallback()}
          />
        ) : null
      )}
      {contentAuthors.map((author, index) => (
        <Author
          key={index}
          name={author?.name || ''}
          bio={author?.bio || ''}
          profileUrl={author?.profileUrl || ''}
          username={author?.username || ''}
          image={author?.image || ''}
          className="mb-8"
        />
      ))}
      <DemoShareLinks
        totalTime={totalTime}
        discussionForumUrl={discussionForumUrl}
      />
      <div className="comments">
        <div className="utterances">
          <div ref={commentBox}></div>
        </div>
      </div>
      <ToastContainer
        hideProgressBar={true}
        position="top-center"
        autoClose={1500}
      />
    </section>
  )
}

export default DemoMain
